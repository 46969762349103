class RemoveVideo {
  constructor() {
    this._Add_HTML_Video();
  }


  //_video() { 

    //$(document).ready(function () {
    //  if ($(window).width() <= 767) {
    // $("#bg-video").remove();
    // }
    //});

  //}

  
  _Add_HTML_Video_() {  
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        // If mobile, then we do all this
      } else {
        // If not mobile then do this
        document.getElementById("bg-video").innerHTML = ' <source src="videos/loop.mp4" type="video/mp4">';
      }
  }






}