class ReadMore {
    constructor() {
        this._more();
    }

    _more() {
        $('.read-more').click(function () {
            $(this).prev().slideToggle();
            if ($(this).remove()) {
                
            } 
        });
    }
}