class TabsGsap {
    constructor() {
        this._tabsGsap1();
        this._tabsGsap2();
        this._tabsGsap3();
        this._tabsGsap4();
        this._tabsGsap5();
        this._tabsGsap6();
       
        
    }
    
    _tabsGsap1() {


        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_1 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_1 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_1 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_1 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100
                }, {
                    duration: 0.7,
                    autoAlpha: 1,
                    x: 0,
                    y: 0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_1 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_1 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
    _tabsGsap2() {


        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_2 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_2 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_2 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_2 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    y:0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_2 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_2 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
    _tabsGsap3() {

        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_3 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_3 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_3 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_3 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    y:0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_3 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_3 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
    _tabsGsap4() {

        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_4 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_4 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_4 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_4 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    y: 0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_4 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_4 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
    _tabsGsap5() {

        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_5 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_5 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_5 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_5 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    y: 0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_5 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_5 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
     _tabsGsap6() {

        // tabs
        var tabLinks = document.querySelectorAll('.gsap_tab_6 .tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.gsap_tab_6 .tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.gsap_tab_6 .tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.gsap_tab_6 .tabcontent', {
                    autoAlpha: 0,
                    x: -400,
                    y: 100,
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    y: 0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.gsap_tab_6 .tablinks.active').classList.remove('active');
                document.querySelector('.gsap_tab_6 .tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }
    
   

}