class svgCustom {
  constructor() {
    this._svg();
  }
  _svg() {
    function mostrar() {
      const zona = document.getElementById(this.dataset.zona)
      console.log(this.dataset)
      if (zona) zona.removeAttribute('hidden')
    }

    function ocultar() {
      const zona = document.getElementById(this.dataset.zona)
      if (zona) zona.setAttribute('hidden', true)
    }

    const zonas = document.querySelectorAll('.zonas')
    zonas.forEach(zona => {
      zona.addEventListener('mouseenter', mostrar)
      zona.addEventListener('mouseout', ocultar)
    });
  }
}