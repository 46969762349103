class AddClass {
    constructor() {
        this._bgBlue1();
        this._bgBlue3();
        this._bgBlue4();
        this._bgBlue5();
        this._bgBlue6();
    }
    _bgBlue1() {
        $('.gsap_tab_1 .change__blue').on('click', function () {
            $('.gsap_tab_1.section__tabs').removeClass('bg-white');
            $('.gsap_tab_1.section__tabs').addClass('bg-dark-2');
        });
        $('.gsap_tab_1 .change__white').on('click', function () {
            $('.gsap_tab_1.section__tabs').removeClass('bg-dark-2');
        });
    }
    _bgBlue3() {
        $('.gsap_tab_3 .change__blue').on('click', function () {
            $('.gsap_tab_3.section__tabs').removeClass('bg-white');
            $('.gsap_tab_3.section__tabs').addClass('bg-dark-2');
        });
        $('.gsap_tab_3 .change__white').on('click', function () {
            $('.gsap_tab_3.section__tabs').removeClass('bg-dark-2');
        });
    }
    _bgBlue4() {
        $('.gsap_tab_4 .change__blue').on('click', function () {
            $('.gsap_tab_4.section__tabs').removeClass('bg-white');
            $('.gsap_tab_4.section__tabs').addClass('bg-dark-2');
        });
        $('.gsap_tab_4 .change__white').on('click', function () {
            $('.gsap_tab_4.section__tabs').removeClass('bg-dark-2');
        });
    }
    _bgBlue5() {
        $('.gsap_tab_5 .change__blue').on('click', function () {
            $('.gsap_tab_5.section__tabs').removeClass('bg-white');
            $('.gsap_tab_5.section__tabs').addClass('bg-dark-2');
        });
        $('.gsap_tab_5 .change__white').on('click', function () {
            $('.gsap_tab_5.section__tabs').removeClass('bg-dark-2');
        });
    }
    _bgBlue6() {
        $('.gsap_tab_6 .change__blue').on('click', function () {
            $('.gsap_tab_6.section__tabs').removeClass('bg-white');
            $('.gsap_tab_6.section__tabs').addClass('bg-dark-2');
        });
        $('.gsap_tab_6 .change__white').on('click', function () {
            $('.gsap_tab_6.section__tabs').removeClass('bg-dark-2');
        });
    }
   
   
}