class SwipperCustom {
  constructor() {
    this._swipperNoticias();
    this._swipperNoticias2();
    this._swipperIcons();
    this._swipperTestimonials();
    this._swipperGallery();
    this._swipperGallery2();
    this._swipperGallery3();
    this._swipperLogos();
    this._swipperLogos2();
  }
  _swipperNoticias() {
    var swiper = new Swiper(".swiperNoticias", {
      slidesPerView: 1.3,
      spaceBetween: 20,
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      allowTouchMove: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1190: {
          slidesPerView: 3,
          spaceBetween: 40,


        },
      },
    });
  }

  _swipperNoticias2() {
    var swiper = new Swiper(".swiperNoticias2", {
      slidesPerView: 1.3,
      spaceBetween: 20,
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      allowTouchMove: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,

      },

      breakpoints: {
        640: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  }

  _swipperIcons() {
    var swiper = new Swiper(".swipperIcons", {
      slidesPerView: 1.2,
      spaceBetween: 0,
      grabCursor: true,
      watchOverflow: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      },
    });
  }

  _swipperTestimonials() {
    var swiper = new Swiper(".swipperTestimonials", {
      slidesPerView: 1.5,
      spaceBetween: 15,
      grabCursor: true,
      watchOverflow: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3.5,
        },
        1400: {
          slidesPerView: 4,
        },
      },
    });
  }

  _swipperGallery() {
    var swiper = new Swiper(".swipperGallery", {
      slidesPerView: 1.2,
      spaceBetween: 0,
      grabCursor: true,
      watchOverflow: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 1.15,
          spaceBetween: 20,
        },
        1400: {
          slidesPerView: 1.2,
          spaceBetween: 30,
        },

      },
    });
  }

  _swipperGallery2() {
    var swiper = new Swiper(".swipperGallery2", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      watchOverflow: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    });
  }
  _swipperGallery3() {
    $('.modal').on('shown.bs.modal', function (e) {
      var swiper = new Swiper(".swipperGallery3", {
        slidesPerView: 1,
        spaceBetween: 0,
        grabCursor: false,
        watchOverflow: true,
        allowTouchMove: false,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        }
      });
    })
    
  }

  _swipperLogos() {
    var swiper = new Swiper(".swipperLogos", {
      slidesPerView: 2,
      spaceBetween: 30,
      grabCursor: true,
      watchOverflow: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        570: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
    });
  }
  _swipperLogos2() {
    var swiper = new Swiper(".swipperLogos2", {
      slidesPerView: 2,
      spaceBetween: 30,
      grabCursor: true,
      watchOverflow: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 60,
        },
      },
    });
  }
}